import { cloneElement, useRef } from 'react'
import { buildIdFromText } from '@/utils'

import styles from './SectionContainer.module.scss'
import classNames from 'classnames'

const SectionContainer = ({
  _type,
  children,
  cmsTitle,
  sectionsLength,
  isInfiniteDuplicate,
  nextSectionType,
  hiddenOnMobile,
}) => {
  const containerRef = useRef(null)
  const id = buildIdFromText(cmsTitle)

  const newElement = cloneElement(children, {
    sectionId: id,
  })

  return (
    <section
      id={id}
      ref={containerRef}
      data-component={_type}
      className={classNames(
        styles.SectionContainer,
        { [styles.isInfiniteDuplicate]: isInfiniteDuplicate },
        {
          [styles.hiddenOnMobile]: hiddenOnMobile,
        },
      )}
      data-sections-length={sectionsLength}
      data-next-section-type={nextSectionType}
    >
      {newElement}
    </section>
  )
}

SectionContainer.displayName = 'SectionContainer'

export default SectionContainer
