import classnames from 'classnames'
import styles from './LithLogo.module.scss'
import Lith from '@/components/_svg/Lith'

const LithLogo = ({ className }) => {
  return (
    <div className={classnames(styles.LithLogo, className)}>
      <Lith className={styles.svg} />
    </div>
  )
}

LithLogo.displayName = 'LithLogo'

export default LithLogo
