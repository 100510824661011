import useBreakpoint from '@/hooks/use-breakpoint'
import styles from './FourOhFour.module.scss'

const FourOhFour = () => {
  const { isMobile } = useBreakpoint()

  return (
    <div className={styles.container}>
      <div className={styles.inner}>
        <div
          className={styles.line}
          data-themed="background-color"
        />
        <div className={styles.textContent}>
          {isMobile && (
            <p
              data-themed="color"
              className={styles.mobileText}
            >
              Error code
            </p>
          )}
          <h1
            className={styles.title}
            data-themed="color"
          >
            404
          </h1>
          <p
            className={styles.error}
            data-themed="color"
          >
            Error
          </p>
          <p
            className={styles.text}
            data-themed="color"
          >
            I’m Sorry I<br />
            can’t find that
          </p>
        </div>
      </div>
    </div>
  )
}

FourOhFour.displayName = 'FourOhFour'

export default FourOhFour
