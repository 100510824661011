import classnames from 'classnames'
import styles from './DefaultGridView.module.scss'
import Link from '@/components/Link/Link'
import { DOC_TYPES, PRODUCT_GRID_VIEW_TYPES } from '@/data'
import SanityImage from '@/components/SanityImage/SanityImage'
import { getCropHeightFromWidth, getCropOptions, getImageBackgroundFromAsset } from '@/utils'
import { Fragment } from 'react'
import useBreakpoint from '@/hooks/use-breakpoint'
import ImageReveal from '@/components/ImageReveal/ImageReveal'
import useStore from '@/store'

export const getProductIndexString = index => {
  if (index < 10) return `00${index + 1}`
  if (index >= 10) return `0${index + 1}`
  if (index >= 100) return `${index + 1}`
}

const EMPTY_SPACES = [2, 5, 15]
const PATTERN_LOOP_AMOUNT = 25 - EMPTY_SPACES.length

const DefaultGridView = ({ className, variants, hoveringSlider, viewType }) => {
  const { isMobile } = useBreakpoint()
  const setCursorState = useStore(state => state.setCursorState)
  const setProductHovering = useStore(state => state.setProductHovering)

  if (!variants?.length) return null

  return (
    <div className={classnames(styles.DefaultGridView, className)}>
      {(hoveringSlider || viewType === PRODUCT_GRID_VIEW_TYPES.DEFAULT) && (
        <div className={styles.grid}>
          {variants.map((variant, i) => {
            const modulo = i % PATTERN_LOOP_AMOUNT
            const isSpacer = EMPTY_SPACES.includes(modulo)

            const item = (
              <article
                key={i}
                className={styles.item}
                data-index={i}
              >
                <Link
                  className={styles.link}
                  link={{
                    linkType: 'internal',
                    link: {
                      _id: 'anyString',
                      _type: DOC_TYPES.PRODUCT,
                      slug: variant.slugPathname,
                    },
                  }}
                  onMouseEnter={() => {
                    setProductHovering(variant)
                    setCursorState('FOCUS_EXPLORE')
                  }}
                  onMouseLeave={() => {
                    setProductHovering(null)
                  }}
                >
                  <p className={styles.index}>{variant.material}</p>
                  <ImageReveal backgroundColor={getImageBackgroundFromAsset(variant.image)}>
                    <div
                      className={styles.imageContainer}
                      data-grid-image={i}
                      data-grid-image-type={PRODUCT_GRID_VIEW_TYPES.DEFAULT}
                    >
                      <SanityImage
                        className={styles.image}
                        image={variant.image}
                        breakpoints={{
                          tablet: {
                            width: 700,
                            image: variant.image,
                            options: getCropOptions(variant.imageOrientation, 'portrait', {
                              height: getCropHeightFromWidth('portrait', 700),
                            }),
                          },
                          xs: {
                            width: 320,
                            image: variant.image,
                            options: getCropOptions(variant.imageOrientation, 'portrait', {
                              height: getCropHeightFromWidth('portrait', 320),
                            }),
                          },
                        }}
                      />
                    </div>
                  </ImageReveal>
                  <div className={styles.titlesContainer}>
                    <p className={styles.titlesContainer__model}>{variant.model}</p>
                    <p className={styles.titlesContainer__title}>{variant.title}</p>
                  </div>
                </Link>
              </article>
            )

            return (
              <Fragment key={i}>
                {isSpacer && !isMobile && <div className={styles.spacer}></div>}
                {item}
              </Fragment>
            )
          })}
        </div>
      )}
    </div>
  )
}

DefaultGridView.displayName = 'DefaultGridView'

export default DefaultGridView
