import classnames from 'classnames'
import styles from './StandaloneMedia.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import VideoLoop from '@/components/VideoLoop/VideoLoop'
import MonoSlashModelText from '@/components/MonoSlashModelText/MonoSlashModelText'
import ContentMask from '@/components/ContentMask/ContentMask'
import ImageReveal from '@/components/ImageReveal/ImageReveal'
import Link from '@/components/Link/Link'
import { getImageBackgroundFromAsset } from '@/utils'
import useBreakpoint from '@/hooks/use-breakpoint'

const StandaloneMedia = ({
  className,
  mediaType,
  image,
  videoLoopDesktop,
  videoLoopMobile,
  titleMono,
  titleModelNumber,
  topRightTitle,
  link,
}) => {
  const { isMobile } = useBreakpoint()

  return (
    <div className={classnames(styles.StandaloneMedia, className)}>
      {(titleMono || titleModelNumber || topRightTitle) && (
        <ContentMask
          innerClassName={styles.topContent}
          animateInView
        >
          <MonoSlashModelText
            monoText={titleMono}
            modelText={titleModelNumber}
          />
          {topRightTitle && (
            <p
              className={styles.topRightTitle}
              data-themed="color"
            >
              {topRightTitle}
            </p>
          )}
        </ContentMask>
      )}
      <ImageReveal
        className={styles.mediaContainer}
        backgroundColor={getImageBackgroundFromAsset(image)}
      >
        {mediaType === 'image' && image?.asset?.url && isMobile !== null && (
          <SanityImage
            image={image}
            className={styles.image}
            width={isMobile ? 375 : 1400}
            height={isMobile ? 210 : 800}
            breakpoints={{
              tablet: {
                width: 2000,
                options: {
                  height: 1120,
                  fit: 'crop',
                },
              },
              xs: {
                width: 700,
                options: {
                  height: 400,
                  fit: 'crop',
                },
              },
            }}
          />
        )}
        {mediaType === 'video' && videoLoopMobile && videoLoopDesktop && (
          <VideoLoop
            desktopLoop={videoLoopDesktop}
            mobileLoop={videoLoopMobile}
            desktopSizeMb={3}
            mobileSizeMb={0.75}
            className={styles.video}
          />
        )}
        <Link
          link={link}
          className={styles.link}
        >
          Go to page
        </Link>
      </ImageReveal>
    </div>
  )
}

StandaloneMedia.displayName = 'StandaloneMedia'

export default StandaloneMedia
