import classnames from 'classnames'
import styles from './ProductGridSlider.module.scss'
import { useCallback, useEffect, useRef } from 'react'
import gsap from 'gsap'
import { Draggable } from 'gsap/dist/Draggable'
import useWindowResize from '@/hooks/use-window-resize'
import { PRODUCT_GRID_VIEW_TYPES } from '@/data'
import useStore from '@/store'

gsap.registerPlugin(Draggable)

const VALUE_TO_VIEW_TYPE = {
  0: PRODUCT_GRID_VIEW_TYPES.SMALL,
  1: PRODUCT_GRID_VIEW_TYPES.DEFAULT,
  2: PRODUCT_GRID_VIEW_TYPES.COLUMN,
}

const ProductGridSlider = ({ className, setViewType, setHoveringSlider, viewType }) => {
  const finalDragValueRef = useRef(1)
  const resizeKey = useWindowResize()
  const draggableRef = useRef(null)
  const inputRef = useRef()
  const knobRef = useRef()
  const areaRef = useRef()
  const setCursorState = useStore(state => state.setCursorState)

  const handleInputslider = useCallback(
    (instance, snap) => {
      if (!instance) return
      const inputslider = instance.target.closest(`.${styles.inputslider}`)
      const fill = inputslider.querySelector(`.${styles.fill}`)
      const values = inputslider.dataset.values.split(',')
      const min = parseFloat(values.first())
      const max = parseFloat(values.last())
      const xPercent = gsap.utils.mapRange(0, instance.maxX, 0, 100, instance.x)
      const relativeValue = gsap.utils.mapRange(0, instance.maxX, min, max, instance.x)
      const finalValue = gsap.utils.snap(values, relativeValue)
      const snapX = gsap.utils.mapRange(min, max, 0, instance.maxX, finalValue)
      const fillWidth = gsap.utils.mapRange(0, instance.maxX, 0, 100, snapX)

      if (snap) {
        gsap.to(instance.target, { duration: 0.2, x: snapX })
        gsap.to(fill, { duration: 0.2, width: `${fillWidth}%` })
      } else {
        values.forEach((value, i) => {
          values[i] = parseFloat(value)
        })

        fill.style.width = `${xPercent}%`

        inputslider.querySelector('input').value = finalValue

        if (finalDragValueRef.current !== finalValue && finalValue) {
          finalDragValueRef.current = finalValue
          setViewType(VALUE_TO_VIEW_TYPE[finalValue])
        }
      }
    },
    [setViewType],
  )

  const initialize = useCallback(() => {
    Element.prototype.addClass = function (className) {
      if (!this.classList.contains(className)) {
        this.classList.add(className)
      }
    }

    Element.prototype.removeClass = function (className) {
      if (this.classList.contains(className)) {
        this.classList.remove(className)
      }
    }

    Array.prototype.first = function () {
      return this[0]
    }

    Array.prototype.last = function () {
      return this[this.length - 1]
    }

    document.querySelectorAll(`.${styles.inputslider}`).forEach(inputslider => {
      const values = inputslider.dataset.values.split(',')
      let unit = ''
      const min = parseFloat(values.first())
      const max = parseFloat(values.last())
      const input = inputslider.querySelector(`.${styles.ProductGridSlider} input`)
      const area = inputslider.querySelector(`.${styles.area}`)
      const knob = inputslider.querySelector(`.${styles.knob}`)

      if (inputslider.dataset.unit) {
        unit = inputslider.dataset.unit
      }

      values.forEach((value, i) => {
        values[i] = value = parseFloat(value)

        const span = document.createElement('span')
        span.innerText = value + unit
        span.setAttribute('data-value', value)

        if (i == 0 && input) {
          span.addClass('selected')
          input.value = value
        }

        span.style.left = `${gsap.utils.mapRange(min, max, 0, 100, value)}%`
      })

      if (draggableRef.current?.length) {
        draggableRef.current[0].kill()
      }

      draggableRef.current = Draggable.create(knob, {
        type: 'x',
        edgeResistance: 1,
        bounds: area,
        throwProps: false,
        onDrag: function () {
          setHoveringSlider(true)
          handleInputslider(this, false)
        },
        onDragEnd: function () {
          setHoveringSlider(false)
          handleInputslider(this, true)
        },
      })
    })
  }, [handleInputslider, setHoveringSlider])

  useEffect(() => {
    setTimeout(() => {
      gsap.set(knobRef.current, {
        x: areaRef.current.offsetWidth * 0.5 - knobRef.current.offsetWidth * 0.5,
      })
    }, 10)
    initialize()
  }, [resizeKey, setHoveringSlider, setViewType, initialize, handleInputslider])

  return (
    <div
      className={classnames(styles.ProductGridSlider, className, {
        [styles.hidden]: viewType === PRODUCT_GRID_VIEW_TYPES.TEXT,
      })}
    >
      <p
        className={styles.viewText}
        data-themed="color"
      >
        View
      </p>
      <div
        className={styles.field}
        onMouseEnter={() => {
          setHoveringSlider(true)
        }}
        onMouseLeave={() => {
          setHoveringSlider(false)
        }}
      >
        <div
          className={styles.inputslider}
          data-value="1"
          data-unit="%"
          data-values="0,1,2"
        >
          <input
            className={styles.input}
            type="text"
            name="input"
            autoComplete="off"
            readOnly
            ref={inputRef}
          />
          <div className={styles.values}></div>
          <div
            className={styles.area}
            ref={areaRef}
          >
            <div
              className={styles.track}
              data-themed="background-color"
            ></div>
            <div
              className={styles.fill}
              data-themed="background-color"
            ></div>
            <div
              className={styles.knob}
              onTouchStart={() => {
                setHoveringSlider(true)
              }}
              ref={knobRef}
              onMouseEnter={() => {
                setCursorState('FOCUS')
              }}
              onMouseLeave={() => {
                setCursorState(null)
              }}
            >
              <div
                className={styles.knobInner}
                data-themed="background-color"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ProductGridSlider.displayName = 'ProductGridSlider'

export default ProductGridSlider
