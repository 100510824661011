import classnames from 'classnames'
import styles from './ProductHeroMobileSlider.module.scss'
import useBreakpoint from '@/hooks/use-breakpoint'
import 'swiper/css'
import Swiper from 'swiper'
import { useEffect, useRef } from 'react'
import SanityImage from '@/components/SanityImage/SanityImage'
import { getCropHeightFromWidth, getCropOptions } from '@/utils'
import useWindowResize from '@/hooks/use-window-resize'
import { DOC_TYPES, PRODUCTS_SLUG } from '@/data'
import Link from '@/components/Link/Link'

const ProductHeroMobileSlider = ({ className, images, imageOrientation, onInit }) => {
  const { isMobile } = useBreakpoint()
  const carouselRef = useRef()
  const resizeKey = useWindowResize()
  const carousel = useRef(null)

  useEffect(() => {
    if (!carouselRef.current) return

    if (carousel.current) {
      carousel.current.destroy()
    }

    if (isMobile) {
      const settings = {
        slidesPerView: 1,
      }

      carousel.current = new Swiper(carouselRef.current, settings)

      if (onInit) onInit(carousel.current)
    }

    return () => {
      if (carousel.current) {
        carousel.current.destroy()
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resizeKey, isMobile])

  if (!isMobile || isMobile === null) return null

  return (
    <div className={classnames(styles.ProductHeroMobileSlider, className)}>
      <div className={styles.inner}>
        <Link
          link={{
            linkType: 'internal',
            label: 'Close',
            link: {
              _id: 'anyString',
              _type: DOC_TYPES.PAGE,
              slug: PRODUCTS_SLUG,
            },
          }}
          className={styles.closeButton}
        />
        <div
          className={styles.carouselContainer}
          ref={carouselRef}
        >
          <div className={classnames(styles.carouselContainerInner, 'swiper-wrapper')}>
            {images.map((image, i) => {
              return (
                <div
                  className={classnames(styles.imageContainer, 'swiper-slide')}
                  key={i}
                >
                  {isMobile !== null && (
                    <SanityImage
                      className={styles.image}
                      image={image}
                      priority
                      height={isMobile ? 375 : 800}
                      width={isMobile ? 375 : 800}
                      breakpoints={{
                        xs: {
                          width: 700,
                          image: image,
                          options: getCropOptions(imageOrientation, 'square', {
                            height: getCropHeightFromWidth('square', 700),
                          }),
                        },
                      }}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

ProductHeroMobileSlider.displayName = 'ProductHeroMobileSlider'

export default ProductHeroMobileSlider
