import classnames from 'classnames'
import styles from './MultiParagraphWithLinks.module.scss'
import MultiParagraphWithLinksRichText from '@/sections/MultiParagraphWithLinks/MultiParagraphWithLinksRichText'
import Link from '@/components/Link/Link'
import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import useInView from '@/hooks/use-in-view'
import ContentMask from '@/components/ContentMask/ContentMask'
import ArrowRight from '@/components/_svg/ArrowRight'

const MultiParagraphWithLinks = ({ className, description, smallerDescription, links }) => {
  const { setElementToObserve, isInView } = useInView()
  const containerRef = useRef()
  const topParagraph = useRef()
  const bottomParagraph = useRef()
  const linkLines = useRef([])

  const resetAnimation = () => {
    if (topParagraph.current) {
      gsap.set(topParagraph.current, {
        autoAlpha: 0,
      })
    }

    if (bottomParagraph.current) {
      gsap.set(bottomParagraph.current, {
        autoAlpha: 0,
      })
    }

    if (linkLines?.current?.length) {
      gsap.set(linkLines?.current, {
        scaleX: 0,
      })
    }
  }

  const animateIn = () => {
    const duration = 1.8
    const ease = 'Power3.easeOut'

    gsap.killTweensOf([topParagraph.current, bottomParagraph.current, ...linkLines?.current])

    if (topParagraph.current) {
      gsap.to(topParagraph.current, {
        autoAlpha: 1,
        duration,
        ease,
      })
    }

    if (bottomParagraph.current) {
      gsap.to(bottomParagraph.current, {
        autoAlpha: 1,
        duration,
        ease,
      })
    }

    if (linkLines?.current?.length) {
      gsap.to(linkLines?.current, {
        scaleX: 1,
        duration,
        ease,
        stagger: 0.1,
      })
    }
  }

  useEffect(() => {
    resetAnimation()
    if (!isInView || !containerRef.current) return
    animateIn()
  }, [isInView])

  if (!description?.length) return null

  return (
    <div
      ref={ref => {
        containerRef.current = ref
      }}
      className={classnames(styles.MultiParagraphWithLinks, className)}
    >
      <div
        className={styles.topParagraph}
        ref={ref => {
          setElementToObserve(ref)
          topParagraph.current = ref
        }}
      >
        <MultiParagraphWithLinksRichText content={description} />
      </div>
      {(smallerDescription?.length > 0 || links?.length > 0) && (
        <div className={styles.bottomContent}>
          {smallerDescription?.length > 0 && (
            <div
              className={styles.bottomContent__descriptionContainer}
              ref={bottomParagraph}
            >
              {smallerDescription?.length > 0 && <MultiParagraphWithLinksRichText content={smallerDescription} />}
            </div>
          )}

          {links?.length > 0 && (
            <div className={styles.bottomContent__linksContainer}>
              <ContentMask
                className={styles.bottomContent__linksContainer__title}
                animateInView
              >
                <span data-themed="color">Site links:</span>
              </ContentMask>
              <ul className={styles.bottomContent__linksContainer__list}>
                {links.map((link, i) => (
                  <li
                    key={i}
                    className={styles.bottomContent__linksContainer__listItem}
                  >
                    <span
                      className={styles.bottomContent__linksContainer__listItemLine}
                      data-themed="border-color"
                      ref={ref => {
                        linkLines.current[i] = ref
                      }}
                    />
                    <ContentMask
                      animateInView
                      innerClassName={styles.bottomContent__linksContainer__linkContainer}
                    >
                      <Link
                        link={link}
                        className={styles.bottomContent__linksContainer__link}
                      >
                        <span className={styles.bottomContent__linksContainer__linkBg} />
                        <span className={styles.bottomContent__linksContainer__linkLabel}>{link.label}</span>
                        <span className={styles.bottomContent__linksContainer__linkArrow}>
                          <ArrowRight />
                        </span>
                      </Link>
                    </ContentMask>
                    {i === links?.length - 1 && (
                      <span
                        className={styles.bottomContent__linksContainer__listItemLine}
                        data-themed="border-color"
                        ref={ref => {
                          linkLines.current[i + 1] = ref
                        }}
                      />
                    )}
                  </li>
                ))}
              </ul>
            </div>
          )}
        </div>
      )}
    </div>
  )
}

MultiParagraphWithLinks.displayName = 'MultiParagraphWithLinks'

export default MultiParagraphWithLinks
