import classnames from 'classnames'
import styles from './ResizingSlider.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import { getCropHeightFromWidth, getCropOptions, getImageBackgroundFromAsset } from '@/utils'
import { useCallback, useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import useWindowResize from '@/hooks/use-window-resize'
import MonoSlashModelText from '@/components/MonoSlashModelText/MonoSlashModelText'
import ContentMask from '@/components/ContentMask/ContentMask'
import ImageReveal from '@/components/ImageReveal/ImageReveal'
import useBreakpoint from '@/hooks/use-breakpoint'
import Link from '@/components/Link/Link'
import { DOC_TYPES } from '@/data'

const ResizingSlider = ({ className, title, products }) => {
  const productContainerRefs = useRef([])
  const productImageContainerRefs = useRef([])
  const productTextContentRefs = useRef([])
  const productPlacementRefs = useRef([])
  const [activeSlide, setActiveSlide] = useState(0)
  const resizeKey = useWindowResize()
  const { isMobile } = useBreakpoint()

  const getPlacementOffsets = () => {
    if (!productPlacementRefs.current?.length) return null
    const offsets = []
    productPlacementRefs.current.forEach(el => {
      if (!el) return
      offsets.push({
        width: el.offsetWidth,
        offsetLeft: el.offsetLeft,
      })
    })
    return offsets
  }

  const getPlacementOffsetByIndex = (productIndex, activeIndex) => {
    if (productIndex < activeIndex) return 0
    if (productIndex === activeIndex) return 1
    if (productIndex === activeIndex + 1) return 2
    return 3
  }

  const goToIndex = useCallback(
    (activeIndex, immediate) => {
      gsap.killTweensOf([
        ...productContainerRefs.current,
        ...productImageContainerRefs.current,
        ...productTextContentRefs.current,
      ])

      const placementOffsets = getPlacementOffsets()
      const productContainerDefaultWidth = productContainerRefs.current[0].offsetWidth
      const ease = 'Power3.easeOut'
      const duration = isMobile ? 0.6 : 1.2

      productContainerRefs.current.forEach((el, i) => {
        if (isMobile === null) return
        if (!el) return

        const indexPosition = getPlacementOffsetByIndex(i, activeIndex)
        const placementOffset = placementOffsets[indexPosition]
        const thirdPlacement = placementOffsets[2]

        gsap[immediate ? 'set' : 'to'](el, {
          x: placementOffset.offsetLeft,
          ease,
          duration,
        })

        const productImageContainer = productImageContainerRefs.current[i]
        if (productImageContainer) {
          gsap[immediate ? 'set' : 'to'](productImageContainer, {
            scale: placementOffset.width / productContainerDefaultWidth,
            ease,
            duration,
          })
        }

        const productTextContent = productTextContentRefs.current[i]
        if (productTextContent) {
          gsap[immediate ? 'set' : 'to'](productTextContent, {
            autoAlpha: isMobile ? (indexPosition < 2 ? 0 : 1) : 1,
            ease,
            duration: duration * 0.5,
          })
          gsap[immediate ? 'set' : 'to'](productTextContent, {
            width: isMobile ? thirdPlacement.width : placementOffset.width,
            ease,
            duration,
          })
        }
      })
    },
    [isMobile],
  )

  useEffect(() => {
    goToIndex(activeSlide)
  }, [activeSlide, goToIndex])

  useEffect(() => {
    setTimeout(() => {
      setActiveSlide(0)
      goToIndex(0, true)
    }, 100)
  }, [resizeKey, goToIndex])

  if (!title || !products?.length) return null

  return (
    <div className={classnames(styles.ResizingSlider, className)}>
      <div className={styles.topContent}>
        <h1
          className={styles.topContent__title}
          data-themed="color"
        >
          <ContentMask
            text={title}
            animateInView
          />
        </h1>
        <p
          className={styles.imagesText}
          data-themed="color"
        >
          <span className={styles.imagesText__length}>
            {products.length > 9 ? products.length : `0${products.length}`}
          </span>
          <span className={styles.imagesText__title}>_Images</span>
        </p>
      </div>
      <div className={styles.carouselContent}>
        <div className={styles.carouselContentInner}>
          <div
            className={styles.productPlacement1}
            ref={ref => {
              productPlacementRefs.current[0] = ref
            }}
          />
          <div
            className={styles.productPlacement2}
            ref={ref => {
              productPlacementRefs.current[1] = ref
            }}
          />
          <div
            className={styles.productPlacement3}
            ref={ref => {
              productPlacementRefs.current[2] = ref
            }}
          />
          <div
            className={styles.productPlacement4}
            ref={ref => {
              productPlacementRefs.current[3] = ref
            }}
          />
          {products.map((product, i) => {
            const firstVariant = product?.productData?.variants[0]
            const indexNumber = i > 9 ? `0${i + 1}` : `00${i + 1}`

            if (!firstVariant) return null

            return (
              <div
                className={classnames(
                  styles.productContainer,
                  { [styles.isPrev]: i <= activeSlide },
                  { [styles.isNext]: i > activeSlide },
                )}
                key={i}
                ref={ref => {
                  productContainerRefs.current[i] = ref
                }}
              >
                <div
                  className={styles.productImageContainer}
                  ref={ref => {
                    productImageContainerRefs.current[i] = ref
                  }}
                  onClick={() => {
                    if (i <= activeSlide) {
                      setActiveSlide(prev => {
                        if (prev === 0) return 0
                        return prev - 1
                      })
                    } else {
                      setActiveSlide(prev => {
                        if (prev === products.length - 2) return products.length - 2
                        return prev + 1
                      })
                    }
                  }}
                >
                  <ImageReveal
                    backgroundColor={getImageBackgroundFromAsset(firstVariant.image)}
                    className={styles.productImageContainerMask}
                  >
                    <SanityImage
                      image={firstVariant.image}
                      className={styles.productImage}
                      breakpoints={{
                        tablet: {
                          width: 1100,
                          image: firstVariant.image,
                          options: getCropOptions(product?.productData?.imageOrientation, 'portrait', {
                            height: getCropHeightFromWidth('portrait', 1100),
                          }),
                        },
                        xs: {
                          width: 700,
                          image: firstVariant.image,
                          options: getCropOptions(product?.productData?.imageOrientation, 'portrait', {
                            height: getCropHeightFromWidth('portrait', 700),
                          }),
                        },
                      }}
                    />
                  </ImageReveal>
                </div>
                <div
                  className={styles.productTextContent}
                  ref={ref => {
                    productTextContentRefs.current[i] = ref
                  }}
                >
                  <Link
                    link={{
                      linkType: 'internal',
                      link: {
                        _id: 'anyString',
                        _type: DOC_TYPES.PRODUCT,
                        slug: product.slug.current,
                      },
                    }}
                    className={styles.productTextContent__link}
                  >
                    <MonoSlashModelText
                      monoText={indexNumber}
                      modelText={product?.productData?.model}
                    />
                    <p className={styles.productTextContent__title}>
                      <span data-themed="color">{product?.title}</span>
                    </p>
                  </Link>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  )
}

ResizingSlider.displayName = 'ResizingSlider'

export default ResizingSlider
