import classnames from 'classnames'
import styles from './ProductGridMovingBoxes.module.scss'
import { useCallback, useEffect, useRef } from 'react'
import useWindowResize from '@/hooks/use-window-resize'
import { Flip } from 'gsap/dist/Flip'
import gsap from 'gsap'
import { PRODUCT_GRID_VIEW_TYPES } from '@/data'

const BOX_OUTLINE_COUNT = 13 // First 13 will animate

gsap.registerPlugin(Flip)

const ProductGridMovingBoxes = ({ className, activeView, setIsMovingBoxes }) => {
  const boxRef = useRef([])
  const resizeKey = useWindowResize()
  const hasAnimated = useRef(false)
  const previousView = useRef(null)

  const animateToItem = useCallback(
    (boxElement, elementToAnimateTo) => {
      if (!boxElement || !elementToAnimateTo) return
      gsap.killTweensOf(boxElement)

      const animate = ({ duration, scale, onComplete }) => {
        Flip.fit(boxElement, elementToAnimateTo, {
          duration: duration,
          ease: 'Power3.easeOut',
          scale,
          onComplete: () => {
            if (onComplete) onComplete()
          },
        })
      }

      if (!hasAnimated.current) {
        setTimeout(() => {
          animate({
            duration: 0.8,
            scale: false,
            onComplete: () => {
              hasAnimated.current = true
            },
          })
        }, 100)
      } else {
        setIsMovingBoxes(true)

        animate({
          duration: 0.6,
          scale: true,
          onComplete: () => {
            setIsMovingBoxes(false)
          },
        })
      }
    },
    [setIsMovingBoxes],
  )

  useEffect(() => {
    if (!activeView || activeView === PRODUCT_GRID_VIEW_TYPES.TEXT) return
    const selector = `[data-grid-image-type="${activeView}"]`

    if (previousView.current === activeView) {
      return
    }

    const items = Array.from(document.querySelectorAll(selector))
    if (items.length) {
      const firstXItems = items.slice(0, BOX_OUTLINE_COUNT)
      firstXItems.forEach((element, i) => {
        const boxElement = boxRef.current[i]
        if (!element || !boxElement) return
        animateToItem(boxElement, element)
      })
    }

    previousView.current = activeView
  }, [activeView, resizeKey, animateToItem])

  return (
    <div
      className={classnames(styles.ProductGridMovingBoxes, className, {
        [styles.textView]: activeView === PRODUCT_GRID_VIEW_TYPES.TEXT,
      })}
      data-active-view={activeView}
    >
      {Array(BOX_OUTLINE_COUNT)
        .fill(null)
        .map((_, i) => (
          <div
            className={styles.box}
            key={i}
            ref={ref => {
              boxRef.current[i] = ref
            }}
          />
        ))}
    </div>
  )
}

ProductGridMovingBoxes.displayName = 'ProductGridMovingBoxes'

export default ProductGridMovingBoxes
