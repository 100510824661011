import classnames from 'classnames'
import styles from './TextView.module.scss'
import Link from '@/components/Link/Link'
import { DOC_TYPES } from '@/data'
import { getProductIndexString } from '@/sections/ProductGrid/DefaultGridView/DefaultGridView'
import useStore from '@/store'
import useBreakpoint from '@/hooks/use-breakpoint'

const TextView = ({ className, products }) => {
  const { isMobile } = useBreakpoint()
  const setProductGridActiveProductIndex = useStore(state => state.setProductGridActiveProductIndex)

  return (
    <div className={classnames(styles.TextView, className)}>
      <div className={styles.headerColumns}>
        <p
          className={styles.headerColumns__colTitle}
          data-themed="color"
        >
          Number
        </p>
        <p
          className={styles.headerColumns__colTitle}
          data-themed="color"
        >
          Product Type
        </p>
        <p
          className={styles.headerColumns__colTitle}
          data-themed="color"
        >
          {isMobile ? 'Weight' : 'Product Finishing'}
        </p>
      </div>
      <div className={styles.grid}>
        {products.map((product, i) => {
          return (
            <article
              key={i}
              className={styles.item}
              data-index={i}
            >
              <Link
                className={styles.link}
                link={{
                  linkType: 'internal',
                  link: {
                    _id: 'anyString',
                    _type: DOC_TYPES.PRODUCT,
                    slug: product.slug.current,
                  },
                }}
                dataTheme="color, background-color"
                onMouseEnter={() => {
                  if (isMobile) return
                  setProductGridActiveProductIndex(i)
                }}
                onMouseLeave={() => {
                  if (isMobile) return
                  setProductGridActiveProductIndex(null)
                }}
              >
                <div className={styles.itemColumn}>
                  <span className={styles.index}>{getProductIndexString(i)}&nbsp;/&nbsp;</span>
                  <span>{product.productData.model}</span>
                </div>
                <div className={styles.itemColumn}>
                  <span>{product.title}</span>
                </div>
                <div className={styles.itemColumn}>
                  {isMobile ? (
                    <span>{product.productData.variants[0]?.weight}</span>
                  ) : (
                    <span>{product.productData.variants.map(variant => variant.material).join(', ')}</span>
                  )}
                </div>
              </Link>
            </article>
          )
        })}
      </div>
    </div>
  )
}

TextView.displayName = 'TextView'

export default TextView
