import classnames from 'classnames'
import styles from './ProductGridImageTextToggle.module.scss'
import { PRODUCT_GRID_VIEW_TYPES } from '@/data'
import { useEffect, useRef } from 'react'

const ProductGridImageTextToggle = ({ className, setViewType, viewType }) => {
  const prevTypeRef = useRef()

  useEffect(() => {
    if (viewType === PRODUCT_GRID_VIEW_TYPES.TEXT) return
    prevTypeRef.current = viewType
  }, [viewType])

  return (
    <div className={classnames(styles.ProductGridImageTextToggle, className)}>
      <button
        className={classnames(styles.imageButton, { [styles.isActive]: viewType !== PRODUCT_GRID_VIEW_TYPES.TEXT })}
        onClick={() => {
          if (viewType === PRODUCT_GRID_VIEW_TYPES.TEXT) {
            setViewType(prevTypeRef.current)
          }
        }}
        data-themed="color"
      >
        IMG
      </button>
      <span
        data-themed="color"
        className={styles.slash}
      >
        /
      </span>
      <button
        onClick={() => {
          setViewType(PRODUCT_GRID_VIEW_TYPES.TEXT)
        }}
        className={classnames(styles.textButton, { [styles.isActive]: viewType === PRODUCT_GRID_VIEW_TYPES.TEXT })}
        data-themed="color"
      >
        TXT
      </button>
    </div>
  )
}

ProductGridImageTextToggle.displayName = 'ProductGridImageTextToggle'

export default ProductGridImageTextToggle
