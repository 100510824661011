import { PortableText } from '@portabletext/react'
import Link from '@/components/Link/Link'
import styles from './MultiParagraphWithLinks.module.scss'

const portableTextSerializer = {
  marks: {
    link: ({ value, text }) => {
      return (
        <Link
          link={{
            ...value,
            label: text,
          }}
          className={styles.richTextLink}
        />
      )
    },
    superscriptText: ({ children }) => {
      return <sup className={styles.sup}>{children}</sup>
    },
  },
  block: {
    normal: ({ children }) => (
      <p
        data-themed="color"
        className={styles.p}
      >
        {children}
      </p>
    ),
  },
}

function MultiParagraphWithLinksRichText({ content }) {
  return (
    <PortableText
      value={content}
      onMissingComponent={false}
      components={portableTextSerializer}
    />
  )
}

MultiParagraphWithLinksRichText.displayName = 'MultiParagraphWithLinksRichText'

export default MultiParagraphWithLinksRichText
