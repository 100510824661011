import classnames from 'classnames'
import styles from './ColumnView.module.scss'
import Link from '@/components/Link/Link'
import { DOC_TYPES, PRODUCT_GRID_VIEW_TYPES } from '@/data'
import SanityImage from '@/components/SanityImage/SanityImage'
import { getCropHeightFromWidth, getCropOptions, getImageBackgroundFromAsset } from '@/utils'
import ImageReveal from '@/components/ImageReveal/ImageReveal'
import useStore from '@/store'

const ColumnView = ({ className, variants, viewType, hoveringSlider }) => {
  const setCursorState = useStore(state => state.setCursorState)
  const setProductHovering = useStore(state => state.setProductHovering)

  if (!variants?.length) return null

  return (
    <div className={classnames(styles.ColumnView, className)}>
      {(hoveringSlider || viewType === PRODUCT_GRID_VIEW_TYPES.COLUMN) && (
        <div className={styles.grid}>
          {variants.map((variant, i) => {
            return (
              <article
                key={i}
                className={styles.item}
                data-index={i}
              >
                <Link
                  className={styles.link}
                  link={{
                    linkType: 'internal',
                    link: {
                      _id: 'anyString',
                      _type: DOC_TYPES.PRODUCT,
                      slug: variant.slugPathname,
                    },
                  }}
                  onMouseEnter={() => {
                    setProductHovering(variant)
                    setCursorState('FOCUS_EXPLORE')
                  }}
                  onMouseLeave={() => {
                    setProductHovering(null)
                  }}
                >
                  <p className={styles.index}>{variant.material}</p>

                  <ImageReveal backgroundColor={getImageBackgroundFromAsset(variant.image)}>
                    <div
                      className={styles.imageContainer}
                      data-grid-image={i}
                      data-grid-image-type={PRODUCT_GRID_VIEW_TYPES.COLUMN}
                    >
                      <SanityImage
                        className={styles.image}
                        image={variant.image}
                        breakpoints={{
                          tablet: {
                            width: 1200,
                            image: variant.image,
                            options: getCropOptions(variant.imageOrientation, 'portrait', {
                              height: getCropHeightFromWidth('portrait', 700),
                            }),
                          },
                          xs: {
                            width: 600,
                            image: variant.image,
                            options: getCropOptions(variant.imageOrientation, 'portrait', {
                              height: getCropHeightFromWidth('portrait', 350),
                            }),
                          },
                        }}
                      />
                    </div>
                  </ImageReveal>
                  <div className={styles.titlesContainer}>
                    <p className={styles.titlesContainer__model}>{variant.model}</p>
                    <p className={styles.titlesContainer__title}>{variant.title}</p>
                  </div>
                </Link>
              </article>
            )
          })}
        </div>
      )}
    </div>
  )
}

ColumnView.displayName = 'ColumnView'

export default ColumnView
