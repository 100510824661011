import classnames from 'classnames'
import styles from './ProductHeroMaterialDropdown.module.scss'
import { useEffect, useRef, useState } from 'react'
import gsap from 'gsap'
import useStore from '@/store'

const ProductHeroMaterialDropdown = ({ className, variants, activeVariantIndex, setActiveVariantIndex, onToggle }) => {
  const [isOpen, setIsOpen] = useState(false)
  const buttonBgRefs = useRef([])
  const buttonTitleRefs = useRef([])
  const buttonIndexRefs = useRef([])
  const listRef = useRef()
  const arrowRef = useRef()
  const setCursorState = useStore(state => state.setCursorState)

  useEffect(() => {
    if (!buttonBgRefs?.current?.length) return
    if (!buttonTitleRefs?.current?.length) return
    if (!buttonIndexRefs?.current?.length) return
    if (!arrowRef.current) return

    if (onToggle) onToggle(isOpen)

    const duration = isOpen ? 0.8 : 0.4
    const ease = 'Power3.easeOut'
    const stagger = isOpen ? 0.1 : 0.05

    gsap.killTweensOf(arrowRef.current)
    gsap.to(arrowRef.current, {
      rotate: isOpen ? '-180deg' : '0deg',
      y: isOpen ? '-25%' : '0%',
      duration,
      ease,
    })

    buttonBgRefs?.current.forEach((el, i) => {
      gsap.killTweensOf(el)
      gsap.to(el, {
        scaleX: isOpen ? 1 : 0,
        duration,
        ease,
        delay: i * stagger,
      })
    })

    buttonTitleRefs?.current.forEach((el, i) => {
      gsap.killTweensOf(el)
      gsap.to(el, {
        autoAlpha: isOpen ? 1 : 0,
        duration,
        ease,
        delay: i * stagger,
      })
    })

    buttonIndexRefs?.current.forEach((el, i) => {
      gsap.killTweensOf(el)
      gsap.to(el, {
        autoAlpha: isOpen ? 0.5 : 0,
        duration,
        ease,
        delay: i * stagger,
      })
    })

    gsap.set(listRef.current, {
      pointerEvents: isOpen ? 'all' : 'none',
    })
  }, [isOpen, onToggle])

  return (
    <div className={classnames(styles.ProductHeroMaterialDropdown, className)}>
      <button
        className={styles.mainButton}
        data-themed="background-color"
        onClick={() => {
          setIsOpen(prev => !prev)
        }}
        onMouseEnter={() => {
          setCursorState('FOCUS')
        }}
        onMouseLeave={() => {
          setCursorState(null)
        }}
      >
        <span
          className={styles.mainButton__materialTitle}
          data-themed="color"
        >
          Material
        </span>
        <span
          className={styles.mainButton__material}
          data-themed="color"
        >
          {variants[activeVariantIndex]?.material}
        </span>
        <span
          className={styles.mainButton__arrow}
          data-themed="border-color"
          ref={arrowRef}
        />
      </button>
      <ul
        className={styles.buttonsList}
        ref={listRef}
      >
        {variants.map((variant, i) => (
          <li
            className={styles.buttonList__item}
            key={i}
          >
            <button
              className={styles.buttonList__button}
              aria-hidden={!isOpen}
              onClick={() => {
                setActiveVariantIndex(i)
                setIsOpen(false)
              }}
              onMouseEnter={() => {
                setCursorState('FOCUS')
              }}
              onMouseLeave={() => {
                setCursorState(null)
              }}
            >
              <span
                className={styles.buttonList__button__bg}
                ref={ref => {
                  buttonBgRefs.current[i] = ref
                }}
              />
              <span
                className={styles.buttonList__button__index}
                ref={ref => {
                  buttonIndexRefs.current[i] = ref
                }}
              >
                (0{i + 1})
              </span>
              <span
                className={styles.buttonList__button__title}
                ref={ref => {
                  buttonTitleRefs.current[i] = ref
                }}
              >
                {variant.material}
              </span>
            </button>
          </li>
        ))}
      </ul>
    </div>
  )
}

ProductHeroMaterialDropdown.displayName = 'ProductHeroMaterialDropdown'

export default ProductHeroMaterialDropdown
