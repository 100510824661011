import classnames from 'classnames'
import styles from './ProductHero.module.scss'
import ProductHeroImageRotator from './ProductHeroImageRotator/ProductHeroImageRotator'
import { useMemo, useRef, useState } from 'react'
import ProductHeroDetails from '@/sections/ProductHero/ProductHeroDetails/ProductHeroDetails'
import ProductHeroMaterialDropdown from '@/sections/ProductHero/ProductHeroMaterialDropdown/ProductHeroMaterialDropdown'
import ProductHeroDetailView from '@/sections/ProductHero/ProductHeroDetailView/ProductHeroDetailView'
import useStore from '@/store'
import Link from '@/components/Link/Link'
import ContentMask from '@/components/ContentMask/ContentMask'
import ProductHeroMobileSlider from '@/sections/ProductHeroMobileSlider/ProductHeroMobileSlider'
import useBreakpoint from '@/hooks/use-breakpoint'
import ArrowRight from '@/components/_svg/ArrowRight'
import { useRouter } from 'next/router'

const getSearchParamFromString = pathname => {
  const query = pathname.split('?')[1]
  if (!query) return null

  // eslint-disable-next-line prefer-template
  return JSON.parse('{"' + decodeURI(query).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g, '":"') + '"}')
}

const ProductHero = ({
  className,
  title,
  variants,
  lifestyleImages,
  imageOrientation,
  model,
  description,
  dimensions,
  placeOfOrigin,
  designedBy,
}) => {
  const router = useRouter()
  const globalData = useStore(state => state.globalData)
  const drawerData = globalData?.navigation?.navigationDrawerContent
  const [activeVariantIndex, setActiveVariantIndex] = useState(
    getSearchParamFromString(router.asPath)?.v ? parseInt(getSearchParamFromString(router.asPath)?.v) : 0,
  )
  const carouselRef = useRef()
  const [activeSlide, setActiveSlide] = useState(0)
  const { isMobile } = useBreakpoint()
  const [materialDropdownOpen, setMaterialDropdownOpen] = useState(false)
  const currentSlideRef = useRef()

  const images = useMemo(() => {
    if (isMobile === null) return

    let _images = []
    if (variants[activeVariantIndex]?.image) {
      _images.push(variants[activeVariantIndex]?.image)
    }
    if (lifestyleImages) {
      _images = [..._images, ...lifestyleImages]
    }

    if (!isMobile) {
      _images = [..._images, ..._images].reverse()
    }

    return _images
  }, [variants, lifestyleImages, activeVariantIndex, isMobile])

  return (
    <div className={classnames(styles.ProductHero, className, { [styles.materialDropdownOpen]: materialDropdownOpen })}>
      <ProductHeroImageRotator
        images={images}
        imageOrientation={imageOrientation}
        onSlideChange={index => {
          if (currentSlideRef.current) {
            currentSlideRef.current.innerText = index + 1
          }
        }}
      />
      <ProductHeroMobileSlider
        images={images}
        imageOrientation={imageOrientation}
        onInit={ref => {
          carouselRef.current = ref
          carouselRef.current.on('slideChange', function () {
            if (!carouselRef.current) return
            setActiveSlide(carouselRef.current?.activeIndex)
          })
        }}
      />
      <div className={styles.header}>
        <p className={styles.model}>
          <ContentMask
            element="span"
            animateInView
            text={model}
          />
        </p>
        <h1 className={styles.title}>
          <ContentMask
            element="span"
            animateInView
            text={title}
          />
        </h1>
        <p className={styles.count}>
          <ContentMask
            element="span"
            animateInView
          >
            <span ref={currentSlideRef}>{activeSlide + 1}</span>
            <span>/{isMobile ? images?.length : images?.length * 0.5}</span>
          </ContentMask>
        </p>
        <div className={styles.arrowsContainer}>
          <button
            className={classnames(styles.arrowsContainer__arrowButton, styles.left, {
              [styles.isActive]: activeSlide > 0,
            })}
            onClick={() => {
              carouselRef.current.slidePrev()
            }}
            aria-label="go to previous slide"
          >
            <ArrowRight />
          </button>
          <button
            className={classnames(styles.arrowsContainer__arrowButton, styles.right, {
              [styles.isActive]: activeSlide < images?.length - 1,
            })}
            onClick={() => {
              carouselRef.current.slideNext()
            }}
            aria-label="go to next slide"
          >
            <ArrowRight />
          </button>
        </div>
      </div>
      {/* {images[0] && (
        <div className={styles.paletteContainer}>
          {Object.keys(images[0]?.asset?.metadata?.palette).map(key => {
            return (
              <div className={styles.paletteContainer__item}>
                <p className={styles.paletteContainer__type}>{key}</p>
                <div
                  className={styles.paletteContainer__box}
                  style={{
                    backgroundColor: images[0]?.asset?.metadata?.palette[key].background,
                  }}
                ></div>
              </div>
            )
          })}
        </div>
      )} */}
      <div className={styles.content}>
        <div
          className={styles.contentHeader}
          data-themed="border-color"
        >
          <p className={styles.contentHeader__details}>
            <ContentMask
              element="span"
              animateInView
              text={'Details'}
            />
          </p>
          <p className={styles.contentHeader__scroll}>
            <ContentMask
              element="span"
              animateInView
              text={'Scroll'}
            />
          </p>
        </div>
        <div className={styles.contentGrid}>
          <div
            className={styles.contentGrid__details}
            data-themed="border-color"
          >
            <ProductHeroDetails
              activeVariantIndex={activeVariantIndex}
              description={description}
              model={model}
              dimensions={dimensions}
              variants={variants}
              placeOfOrigin={placeOfOrigin}
              setActiveVariantIndex={setActiveVariantIndex}
              designedBy={designedBy}
            />
          </div>
          <div className={styles.contentGrid__detailViewAndDropdownContainer}>
            <p className={styles.lowerTitleSubtitle}>
              <ContentMask
                element="span"
                animateInView
                text="(Product)"
              />
            </p>
            <p className={styles.lowerTitle}>
              <ContentMask
                element="span"
                animateInView
                text={title}
              />
            </p>
            <ProductHeroDetailView
              activeVariant={variants[activeVariantIndex]}
              imageOrientation={imageOrientation}
              className={styles.detailViewContainer}
            />
            <ProductHeroMaterialDropdown
              variants={variants}
              className={styles.materialDropdown}
              activeVariantIndex={activeVariantIndex}
              setActiveVariantIndex={setActiveVariantIndex}
              onToggle={isOpen => {
                setMaterialDropdownOpen(isOpen)
              }}
            />
            <Link
              link={{
                linkType: 'external',
                link: `mailto:${drawerData?.contactEmail}`,
              }}
              className={styles.contactLink}
              dataTheme="border-color"
            >
              <span className={styles.contactLink__text}>Inquire about product</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

ProductHero.displayName = 'ProductHero'

export default ProductHero
