import classnames from 'classnames'
import styles from './TwoImagesAndText.module.scss'
import SanityImage from '@/components/SanityImage/SanityImage'
import ImageReveal from '@/components/ImageReveal/ImageReveal'
import { getImageBackgroundFromAsset } from '@/utils'
import useBreakpoint from '@/hooks/use-breakpoint'

const TwoImagesAndText = ({ className, title, smallImage, largeImage }) => {
  const { isMobile } = useBreakpoint()

  if (!smallImage?.asset?.url || !largeImage?.asset?.url || !title) return null

  return (
    <div className={classnames(styles.TwoImagesAndText, className)}>
      <h1
        className={styles.title}
        data-themed="color"
      >
        {title}
      </h1>
      <div className={styles.images}>
        <ImageReveal
          backgroundColor={getImageBackgroundFromAsset(smallImage)}
          className={styles.mediaContainer}
        >
          {isMobile !== null && (
            <SanityImage
              width={isMobile ? 170 : 460}
              height={isMobile ? 320 : 550}
              image={smallImage}
              className={styles.smallImage}
              breakpoints={{
                laptop: {
                  width: 800,
                  image: smallImage,
                },
                xs: {
                  width: 260,
                  image: smallImage,
                },
              }}
            />
          )}
        </ImageReveal>
        <ImageReveal
          backgroundColor={getImageBackgroundFromAsset(largeImage)}
          className={styles.mediaContainer}
        >
          {isMobile !== null && (
            <SanityImage
              width={isMobile ? 200 : 690}
              height={isMobile ? 233 : 800}
              image={largeImage}
              className={styles.largeImage}
              breakpoints={{
                laptop: {
                  width: 1400,
                  image: largeImage,
                },
                xs: {
                  width: 460,
                  image: largeImage,
                },
              }}
            />
          )}
        </ImageReveal>
      </div>
    </div>
  )
}

TwoImagesAndText.displayName = 'TwoImagesAndText'

export default TwoImagesAndText
