import classnames from 'classnames'
import styles from './ProductGridHeader.module.scss'
import ProductGridSlider from '@/sections/ProductGrid/ProductGridSlider/ProductGridSlider'
import ProductGridImageTextToggle from '@/sections/ProductGrid/ProductGridImageTextToggle/ProductGridImageTextToggle'
import useStore from '@/store'
import { useEffect } from 'react'

const ProductGridHeader = ({ className, variantCount, title, setViewType, setHoveringSlider, viewType }) => {
  const setProductGridHeaderContent = useStore(state => state.setProductGridHeaderContent)
  const productGridHeaderContent = useStore(state => state.productGridHeaderContent)

  useEffect(() => {
    setProductGridHeaderContent({
      title: title || 'All Products',
      count: `0_${variantCount}`,
    })

    return () => {
      setProductGridHeaderContent({
        title: '',
        count: '',
      })
    }
  }, [title, variantCount, setProductGridHeaderContent])

  return (
    <div className={classnames(styles.ProductGridHeader, className)}>
      <div className={styles.titleCountContainer}>
        <h1
          data-themed="color"
          className={styles.title}
        >
          {productGridHeaderContent.title}
        </h1>
        <p
          data-themed="color"
          className={styles.count}
        >
          {productGridHeaderContent.count}
        </p>
      </div>
      <div className={styles.sliderAndTextViewContainer}>
        <ProductGridImageTextToggle
          viewType={viewType}
          setViewType={setViewType}
        />
        <ProductGridSlider
          viewType={viewType}
          setViewType={setViewType}
          setHoveringSlider={setHoveringSlider}
        />
      </div>
    </div>
  )
}

ProductGridHeader.displayName = 'ProductGridHeader'

export default ProductGridHeader
