import classnames from 'classnames'
import styles from './LinkBlock.module.scss'
import Link from '@/components/Link/Link'
import ArrowRight from '@/components/_svg/ArrowRight'
import ContentMask from '@/components/ContentMask/ContentMask'
import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import useInView from '@/hooks/use-in-view'

const LinkBlock = ({ className, title, link, showProductCount, productCount }) => {
  const { setElementToObserve, isInView } = useInView()
  const lineRef = useRef()

  useEffect(() => {
    if (!isInView || !lineRef.current) return
    gsap.killTweensOf(lineRef.current)
    gsap.to(lineRef.current, {
      scaleX: 1,
      duration: 1.8,
      ease: 'Power3.easeInOut',
    })
  }, [isInView])

  if (!title || !link) return null

  return (
    <div
      className={classnames(styles.LinkBlock, className)}
      ref={ref => {
        setElementToObserve(ref)
      }}
    >
      <div className={styles.line}>
        <div
          data-themed="background-color"
          className={styles.lineInner}
          ref={lineRef}
        />
      </div>
      <div className={styles.content}>
        <h1 className={styles.title}>
          <ContentMask
            text={title}
            animateInView
            element="span"
          />
        </h1>
        <div className={styles.contentLinkAndCount}>
          <Link
            className={styles.link}
            link={link}
            disableThemeAttribute
          >
            <ContentMask
              animateInView
              element="span"
              innerClassName={styles.linkInner}
              duration={1.4}
            >
              <span
                className={styles.linkTitle}
                data-themed="color"
              >
                {link?.label}
              </span>
              <span className={styles.linkTitle__svgContainer}>
                <ArrowRight className={styles.arrowRight} />
              </span>
            </ContentMask>
          </Link>
          {showProductCount && (
            <ContentMask
              animateInView
              element="div"
              className={styles.productCount}
              delay={0.2}
              duration={1.4}
            >
              <span data-themed="color">0_{productCount}</span>
            </ContentMask>
          )}
        </div>
      </div>
    </div>
  )
}

LinkBlock.displayName = 'LinkBlock'

export default LinkBlock
