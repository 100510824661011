const Lith = ({ className }) => {
  return (
    <svg
      className={className}
      viewBox="0 0 1358 359"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 252.519V20.2455C0 9.06421 9.0642 0 20.2455 0H87.9307C98.3953 0 107.279 7.67029 108.804 18.0231L118.455 83.5093C124.176 122.332 157.489 151.096 196.731 151.096H313.533C319.36 151.096 324.083 155.819 324.083 161.646V336.915C324.083 348.568 314.637 358.014 302.984 358.014H105.496C47.232 358.014 0 310.783 0 252.519ZM703.051 23.0152C703.051 10.3042 713.355 0 726.066 0H991.48C1004.19 0 1014.5 10.3042 1014.5 23.0152V56.9763C1014.5 59.0948 1012.78 60.8122 1010.66 60.8122H956.312C977.05 80.0765 990.025 107.583 990.025 138.121V353.708C990.025 356.621 987.663 358.983 984.75 358.983H737.245C734.332 358.983 731.971 356.621 731.971 353.708V138.121C731.971 107.583 744.946 80.0765 765.683 60.8122H706.887C704.768 60.8122 703.051 59.0948 703.051 56.9763V23.0152ZM1064.07 7.67172V358.015H1189.26V150.437H1232.95V358.015H1357.4V7.67172C1357.4 3.43475 1353.97 0 1349.73 0H1317.32C1314.81 0 1312.78 2.03111 1312.78 4.5366C1312.78 18.5083 1301.46 29.8345 1287.48 29.8345H1133.99C1120.02 29.8345 1108.7 18.5083 1108.7 4.5366C1108.7 2.03111 1106.66 0 1104.16 0H1071.75C1067.51 0 1064.07 3.43475 1064.07 7.67172ZM390.968 0C376.402 0 364.594 11.808 364.594 26.3739V56.9764C364.594 59.0949 366.311 60.8123 368.43 60.8123H402.719V150.457H368.725C366.606 150.457 364.889 152.174 364.889 154.293V332.626C364.889 347.192 376.697 359 391.263 359H631.856C646.422 359 658.23 347.192 658.23 332.626V154.293C658.23 152.174 656.513 150.457 654.394 150.457H619.791V60.8123H652.66C655.573 60.8123 657.935 58.4507 657.935 55.5375V26.3739C657.935 11.808 646.127 0 631.561 0H390.968Z"
        fill="currentColor"
        data-themed="color"
      />
    </svg>
  )
}

Lith.displayName = 'Lith'

export default Lith
