import classnames from 'classnames'
import styles from './ProductGrid.module.scss'
import DefaultGridView from '@/sections/ProductGrid/DefaultGridView/DefaultGridView'
import ColumnView from '@/sections/ProductGrid/ColumnView/ColumnView'
import SmallGridView from '@/sections/ProductGrid/SmallGridView/SmallGridView'
import ProductGridHeader from '@/sections/ProductGrid/ProductGridHeader/ProductGridHeader'
import { useContext, useEffect, useState } from 'react'
import { PRODUCT_GRID_VIEW_TYPES } from '@/data'
import { ScrollContext } from '@/context/Scroll'
import ProductGridMovingBoxes from '@/sections/ProductGrid/ProductGridMovingBoxes/ProductGridMovingBoxes'
import TextView from '@/sections/ProductGrid/TextView/TextView'
import useStore from '@/store'

const ProductGrid = ({ className, products, title }) => {
  const [viewType, setViewType] = useState(PRODUCT_GRID_VIEW_TYPES.DEFAULT)
  const [isMovingBoxes, setIsMovingBoxes] = useState(false)
  const { scroll } = useContext(ScrollContext)
  const [hoveringSlider, setHoveringSlider] = useState()
  const setProductGridProducts = useStore(state => state.setProductGridProducts)
  const setBodyHeightChangeKey = useStore(state => state.setBodyHeightChangeKey)
  const variants = products.flatMap(product => {
    const _variants = product.productData.variants.map((variant, i) => ({
      title: product?.title,
      model: product?.productData?.model,
      imageOrientation: product?.productData?.imageOrientation,
      slug: product?.slug?.current,
      material: variant?.material,
      slugPathname: i > 0 ? `${product?.slug?.current}?v=${i}` : product?.slug?.current,
      ...variant,
    }))
    return _variants
  })

  useEffect(() => {
    scroll?.resize()
    setTimeout(() => {
      scroll?.resize()
      setBodyHeightChangeKey(Date.now())
    }, 500)
  }, [viewType, scroll, setBodyHeightChangeKey])

  useEffect(() => {
    setProductGridProducts(products)
  }, [products, setProductGridProducts])

  return (
    <div
      className={classnames(styles.ProductGrid, className)}
      data-hovering-slider={hoveringSlider}
      data-is-moving-boxes={isMovingBoxes}
      data-view-type={viewType}
    >
      <ProductGridHeader
        variantCount={variants.length}
        title={title}
        setViewType={setViewType}
        className={styles.header}
        setHoveringSlider={setHoveringSlider}
        viewType={viewType}
      />
      <div className={styles.gridsInner}>
        <ProductGridMovingBoxes
          activeView={viewType}
          className={styles.movingBoxes}
          setIsMovingBoxes={setIsMovingBoxes}
        />
        <DefaultGridView
          viewType={viewType}
          hoveringSlider={hoveringSlider}
          variants={variants}
          className={classnames(styles.grid, { [styles.isActive]: viewType === PRODUCT_GRID_VIEW_TYPES.DEFAULT })}
          isActive={viewType === PRODUCT_GRID_VIEW_TYPES.DEFAULT}
        />
        <ColumnView
          viewType={viewType}
          hoveringSlider={hoveringSlider}
          variants={variants}
          className={classnames(styles.grid, {
            [styles.isActive]: viewType === PRODUCT_GRID_VIEW_TYPES.COLUMN,
          })}
          isActive={viewType === PRODUCT_GRID_VIEW_TYPES.COLUMN}
        />
        <SmallGridView
          viewType={viewType}
          hoveringSlider={hoveringSlider}
          variants={variants}
          className={classnames(styles.grid, { [styles.isActive]: viewType === PRODUCT_GRID_VIEW_TYPES.SMALL })}
          isActive={viewType === PRODUCT_GRID_VIEW_TYPES.SMALL}
        />
        <TextView
          variants={variants}
          className={classnames(styles.grid, {
            [styles.isActive]: viewType === PRODUCT_GRID_VIEW_TYPES.TEXT,
          })}
          isActive={viewType === PRODUCT_GRID_VIEW_TYPES.COLUMN}
          products={products}
        />
      </div>
    </div>
  )
}

ProductGrid.displayName = 'ProductGrid'

export default ProductGrid
