import classnames from 'classnames'
import styles from './ProductHeroImageRotator.module.scss'
import { useCallback, useContext, useEffect, useRef, useState } from 'react'
import SanityImage from '@/components/SanityImage/SanityImage'
import { getCropHeightFromWidth, getCropOptions, lerp } from '@/utils'
import gsap from 'gsap'
import useWindowResize from '@/hooks/use-window-resize'
import { ScrollContext } from '@/context/Scroll'
import useBreakpoint from '@/hooks/use-breakpoint'
import classNames from 'classnames'
import useStore from '@/store'
import Link from '@/components/Link/Link'
import { DOC_TYPES, PRODUCTS_SLUG } from '@/data'
import { HEADER_ID } from '@/components/Navigation/Navigation'
import useInView from '@/hooks/use-in-view'
import { Draggable } from 'gsap/dist/Draggable'

gsap.registerPlugin(Draggable)

const DURATION = 1
const EASE = 'Power4.easeOut'

const ProductHeroImageRotator = ({ className, images, imageOrientation, onResize, onSlideChange }) => {
  // images = useMemo(() => {
  //   return [...images].reverse()
  // }, [images])
  const containerRef = useRef()
  const trackRef = useRef()
  const innerRef = useRef()
  const [isExpanded, setIsExpanded] = useState(false)
  const resizeKey = useWindowResize()
  const { scroll } = useContext(ScrollContext)
  const boxSizingRef = useRef()
  const { isMobile } = useBreakpoint()
  const hasFired = useRef(false)
  const setCursorState = useStore(state => state.setCursorState)
  const isExpandedRef = useRef(false)
  const [rotatorActive, setRotatorActive] = useState(false)
  const rafRef = useRef()
  const rafCountRef = useRef(0)
  const { setElementToObserve, isInView } = useInView({ fireOnce: false })
  const imageContainerRefs = useRef([])
  const calculationsRef = useRef({})
  const draggableRef = useRef(null)
  const dragDistanceTarget = useRef(0)
  const dragDistanceCurrent = useRef(0)
  const dragDistanceActiveTarget = useRef(0)
  const dragDistanceActiveCurrent = useRef(0)
  const prevActiveIndex = useRef(null)
  const disableCalculations = useRef(false)
  const showMainContent = useStore(state => state.showMainContent)

  const calculations = useCallback(() => {
    if (disableCalculations.current) return

    const itemWidth = boxSizingRef?.current?.offsetWidth
    const totalWidth = itemWidth * images?.length
    rafCountRef.current = totalWidth * 100000000000 - itemWidth * images?.length * 0.5 // fix for weird image position issue

    calculationsRef.current = {
      itemWidth,
      totalWidth,
    }

    if (draggableRef.current?.length) {
      draggableRef.current.forEach(ref => {
        ref.kill()
      })
    }
    imageContainerRefs.current = imageContainerRefs.current.filter(ref => ref)

    draggableRef.current = Draggable.create(imageContainerRefs.current, {
      type: 'x',
      bounds: {
        minX: 0,
        maxX: 0,
      },
      edgeResistance: 0.2,
      onDrag() {
        dragDistanceActiveTarget.current = this.x
      },
      onDragEnd() {
        dragDistanceTarget.current = dragDistanceTarget.current + dragDistanceActiveTarget.current
        dragDistanceActiveTarget.current = 0
      },
    })
  }, [images])

  const initRaf = useCallback(() => {
    if (!rafCountRef.current) {
      calculations()
    }

    rafRef.current = requestAnimationFrame(initRaf)

    dragDistanceCurrent.current = lerp(dragDistanceCurrent.current, dragDistanceTarget.current, 0.1)
    dragDistanceActiveCurrent.current = lerp(dragDistanceActiveCurrent.current, dragDistanceActiveTarget.current, 0.1)

    rafCountRef.current = rafCountRef.current + 1
    const x = rafCountRef.current + (dragDistanceCurrent.current + dragDistanceActiveCurrent.current) * -1

    let wallDistance = 1000000000000
    let closestToWall = null
    imageContainerRefs.current.forEach((el, i) => {
      if (!el) return
      const itemWidth = calculationsRef.current.itemWidth
      const originalOffset = itemWidth * i
      const delta =
        calculationsRef.current.totalWidth -
        itemWidth * 2 -
        (((x + originalOffset) % calculationsRef.current.totalWidth) - itemWidth)
      el.style.transform = `translate(${delta}px,0px)`

      const leftOffset = el.getBoundingClientRect().left + itemWidth
      if (leftOffset > 0 && leftOffset < wallDistance) {
        closestToWall = parseInt(el.dataset.index)
        wallDistance = leftOffset
      }
    })

    if (onSlideChange && prevActiveIndex.current !== closestToWall) {
      prevActiveIndex.current = closestToWall
      onSlideChange(closestToWall)
    }
  }, [onSlideChange, calculations])

  const killRaf = () => {
    if (rafRef.current) {
      cancelAnimationFrame(rafRef.current)
      rafRef.current = null
    }
  }

  useEffect(() => {
    calculations()
  }, [resizeKey, calculations])

  useEffect(() => {
    if (!showMainContent) return

    killRaf()

    if (isInView) {
      initRaf()
    }

    return () => {
      if (rafRef.current) cancelAnimationFrame(rafRef.current)
    }
  }, [initRaf, isInView, resizeKey, calculations, showMainContent])

  const getDefaultImageHeight = () => {
    if (!boxSizingRef.current) return
    return boxSizingRef.current.offsetHeight
  }

  const minimizeContent = useCallback(
    immediate => {
      if (getDefaultImageHeight() === 0) return

      disableCalculations.current = true

      if (scroll) {
        scroll.start()
      }

      const header = document.getElementById(HEADER_ID)
      if (header) {
        gsap.killTweensOf(header)
        gsap.to(header, {
          y: 0,
        })
      }

      if (innerRef.current) {
        gsap.killTweensOf(innerRef.current)
        gsap[immediate ? 'set' : 'to'](innerRef.current, {
          height: getDefaultImageHeight(),
          ease: EASE,
          duration: DURATION,
          onComplete: () => {
            scroll?.resize()
          },
        })
      }
      if (trackRef.current) {
        gsap.killTweensOf(trackRef.current)
        gsap[immediate ? 'set' : 'to'](trackRef.current, {
          scale: 1,
          ease: EASE,
          duration: DURATION,
          onComplete: () => {
            disableCalculations.current = false
          },
        })
      }
    },
    [scroll],
  )

  const expandContent = useCallback(
    immediate => {
      if (getDefaultImageHeight() === 0) return

      disableCalculations.current = true

      const scale = window.innerHeight / getDefaultImageHeight()

      const header = document.getElementById(HEADER_ID)
      if (header) {
        gsap.killTweensOf(header)
        gsap.to(header, {
          y: '-105%',
        })
      }

      if (scroll) {
        scroll.scrollTo(containerRef.current, {
          duration: 0.7,
          lock: true,
          onComplete: () => {
            scroll?.stop()
          },
        })
      }

      if (innerRef.current) {
        gsap.killTweensOf(innerRef.current)
        gsap[immediate ? 'set' : 'to'](innerRef.current, {
          height: getDefaultImageHeight() * scale,
          ease: EASE,
          duration: DURATION,
        })
      }
      if (trackRef.current) {
        gsap.killTweensOf(trackRef.current)
        gsap[immediate ? 'set' : 'to'](trackRef.current, {
          scale: scale,
          ease: EASE,
          duration: DURATION,
          onComplete: () => {
            scroll?.resize()
            disableCalculations.current = false
          },
        })
      }
    },
    [scroll],
  )

  const handleDoubleClick = () => {
    setIsExpanded(prev => !prev)
  }

  useEffect(() => {
    if (isExpanded) {
      if (!isExpandedRef.current) {
        setCursorState('FOCUS_DBL_CLICK_CLOSE')
        expandContent(!hasFired.current)
      }
    } else {
      if (isExpandedRef.current) {
        setCursorState(null)
        minimizeContent(!hasFired.current)
      }
    }

    isExpandedRef.current = isExpanded

    setTimeout(() => {
      hasFired.current = true
      setRotatorActive(true)
    }, 100)

    if (onResize) onResize(isExpanded)
  }, [isExpanded, resizeKey, expandContent, minimizeContent, onResize, setCursorState])

  if (isMobile || isMobile === null) return null

  return (
    <div
      ref={ref => {
        containerRef.current = ref
        setElementToObserve(ref)
      }}
      className={classnames(
        styles.ProductHeroImageRotator,
        className,
        { [styles.isActive]: rotatorActive },
        { [styles.isExpanded]: isExpanded },
      )}
      style={{
        '--number-to-loop': images.length * 0.5,
        '--duration': `${images.length * 4}s`,
      }}
      onDoubleClick={handleDoubleClick}
    >
      <Link
        link={{
          linkType: 'internal',
          label: 'Close',
          link: {
            _id: 'anyString',
            _type: DOC_TYPES.PAGE,
            slug: PRODUCTS_SLUG,
          },
        }}
        className={styles.closeButton}
      />
      <div
        className={styles.inner}
        ref={innerRef}
        onMouseEnter={() => {
          setCursorState('FOCUS_DBL_CLICK_EXPAND')
        }}
        onMouseLeave={() => {
          setCursorState(null)
        }}
      >
        <div
          className={styles.boxSizingReference}
          ref={boxSizingRef}
        />
        <div
          className={styles.track}
          ref={trackRef}
        >
          <div className={styles.track__inner}>
            {images.map((image, i) => {
              const actualImageLength = images.length * 0.5
              const index = actualImageLength - (i % actualImageLength) - 1

              return (
                <div
                  key={i}
                  className={styles.imageContainer}
                  ref={ref => {
                    imageContainerRefs.current[i] = ref
                  }}
                  // data-index={(i % (images.length - LOOPED_NUMBER_AT_END)) - 1}
                  data-index={index}
                  data-url={image?.asset?.url}
                >
                  {isMobile !== null && (
                    <SanityImage
                      image={image}
                      className={styles.image}
                      priority
                      width={isMobile ? 375 : 500}
                      height={isMobile ? 375 : 500}
                      breakpoints={{
                        tablet: {
                          width: 1200,
                          image: image,
                          options: getCropOptions(imageOrientation, 'square', {
                            height: getCropHeightFromWidth('square', 1200),
                          }),
                        },
                        xs: {
                          width: 600,
                          image: image,
                          options: getCropOptions(imageOrientation, 'square', {
                            height: getCropHeightFromWidth('square', 600),
                          }),
                        },
                      }}
                    />
                  )}

                  {isExpanded && (
                    <SanityImage
                      image={image}
                      className={classNames(styles.image, styles.large)}
                      breakpoints={{
                        tablet: {
                          width: 2500,
                          image: image,
                          options: getCropOptions(imageOrientation, 'square', {
                            height: getCropHeightFromWidth('square', 2500),
                          }),
                        },
                      }}
                    />
                  )}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </div>
  )
}

ProductHeroImageRotator.displayName = 'ProductHeroImageRotator'

export default ProductHeroImageRotator
