import classnames from 'classnames'
import styles from './TestComponent.module.scss'

const TestComponent = ({ className }) => {
  return <div className={classnames(styles.TestComponent, className)}>TestComponent component</div>
}

TestComponent.displayName = 'TestComponent'

export default TestComponent
