import classnames from 'classnames'
import styles from './ProductHeroDetailView.module.scss'
import ArrowRight from '@/components/_svg/ArrowRight'
import useStore from '@/store'

const ProductHeroDetailView = ({ className, activeVariant, imageOrientation }) => {
  const setProductZoomImage = useStore(state => state.setProductZoomImage)
  const setCursorState = useStore(state => state.setCursorState)

  return (
    <button
      onClick={() => {
        setProductZoomImage({
          image: activeVariant?.image,
          imageOrientation,
        })
      }}
      className={classnames(styles.ProductHeroDetailView, className)}
      onMouseEnter={() => {
        setCursorState('FOCUS')
      }}
      onMouseLeave={() => {
        setCursorState(null)
      }}
    >
      <div
        className={styles.inner}
        data-themed="border-color"
      >
        <ArrowRight className={styles.arrowRight} />
        <span
          data-themed="color"
          className={styles.detailView}
        >
          (Detail View)
        </span>
      </div>
    </button>
  )
}

ProductHeroDetailView.displayName = 'ProductHeroDetailView'

export default ProductHeroDetailView
